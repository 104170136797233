.banner {
  max-width: 100%;
  width: 100%;
  height: 195px;
  display: table;
  padding: 25px;
  flex: 1;

  @include linear-gradient-b(
    90deg,
    $dashboard-background-gradient-first-color,
    $dashboard-background-gradient-second-color,
    $dashboard-background-gradient-third-color
  );

  &__body {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  &__buttons {
    display: inline-block;
    vertical-align: middle;

    @media #{$medium-and-down} {
      .btn {
        font-size: 16px !important;
        margin: 19px 0 0 0;
      }

      .btn:first-child {
        margin-right: 20px;
      }
    }

    @media #{$small-and-down} {
      .btn {
        width: 80%;
        font-size: 14px !important;
        margin: 19px 0 0 0;
        padding: 0 !important;

        & i {
          float: none !important;
          vertical-align: top;
        }
      }

      .btn:first-child {
        margin-right: 0px;
      }
    }
  }

  &__slogan {
    display: inline-block;
    vertical-align: middle;
    margin-right: 34px;
    font-size: 14px;
    text-align: left;
    font-weight: $font-weight-medium;
    color: #363552;
    line-height: 30px;

    &__heading {
      font-size: 20px;
    }

    @media #{$medium-and-down} {
      text-align: center;
      margin-right: 0;
    }
  }
}
