.clock {
  font-family: $font-family-roboto;
  background-color: #ffffff;
  &__hours-minutes {
    display: inline-block;
    font-size: 24px;
    font-weight: $font-weight-medium;
  }

  &__seconds {
    display: inline-block;
    font-size: 12px;
    color: $clock-minutes-color;
  }

  &__label {
    font-size: 12px;
    color: $clock-label-color;
  }
}

.clock-wrapper {
  margin-left: auto;
  margin-right: 25px;
  padding: 10px 25px;
  border-right: 1px solid $clock-wrapper-border-color;
}
