.dashboard-table-wrapper {
  min-height: 300px;
  height: 100%;
  position: relative;

  @media #{$medium-and-down} {
    height: auto;
    min-height: 180px;
  }
}

.table {
  width: 100%;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $secondary-font-color;

  &--fixed {
    table-layout: fixed;
  }

  &--scrollable {
    overflow-x: auto;
    white-space: nowrap;
  }

  &__thead {
    font-weight: $font-weight-bold;
    color: $base-font-color;
  }

  &__td {
    padding: $table-cell-top-bottom-padding $table-cell-sides-padding;
    border-right: 1px solid $table-secondary-border-color;

    // input:not(.select-dropdown) {
    //   height: auto !important;
    // }
    input {
      height: 1.5rem !important;
    }

    &--last {
      border-right: 0px;
    }

    @media #{$large-and-up} {
      &--first {
        padding: $table-cell-top-bottom-padding $table-cell-sides-padding $table-cell-top-bottom-padding
          $table-first-cell-left-padding;
      }
    }

    @media #{$medium-and-down} {
      border: 0px;
    }
  }

  &__th {
    padding: $table-cell-top-bottom-padding $table-cell-sides-padding;
    border-right: 1px solid $table-secondary-border-color;
    border-top: 1px solid $table-secondary-border-color;

    @media #{$large-and-up} {
      &--first {
        padding: $table-cell-top-bottom-padding $table-cell-sides-padding $table-cell-top-bottom-padding
          $table-first-cell-left-padding;
      }
    }
    &--last {
      border-right: 0px;
    }
    @media #{$medium-and-down} {
      border: 0px;
    }
    &--dashboard-padding {
      padding: 5px 5px 10.5px 0px;
    }
  }
}

.modal-table {
  &__thead {
    border-bottom: solid 2px $base-button-background-color;
  }
  &__th {
    font-weight: $font-weight-bold;
    padding: 0 0 10px 0;
    /* Two items */
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ & {
      &:nth-child(1) {
        width: 28%;
      }
    }
    /* Three items */
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ & {
      &:nth-child(1) {
        width: 28%;
      }
    }
    /* Four items */
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ & {
      &:nth-child(1) {
        width: 28%;
      }
    }
    /* Five items */
    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ & {
      &:nth-child(1) {
        width: 28%;
      }
    }
    /* Six items */
    &:first-child:nth-last-child(6),
    &:first-child:nth-last-child(6) ~ & {
      &:nth-child(1) {
        width: 28%;
      }
    }
    /* Seven items */
    &:first-child:nth-last-child(7),
    &:first-child:nth-last-child(7) ~ & {
      &:nth-child(1) {
        width: 20%;
      }
    }
    /* Eight items */
    &:first-child:nth-last-child(8),
    &:first-child:nth-last-child(8) ~ & {
      &:nth-child(1) {
        width: 19%;
      }
    }
  }
  &__tr {
    border-bottom: none;
    &--colored {
      background-color: lavender;
    }
  }
  &__td {
    padding: 5px 5px 5px 0px;

    &--last {
      padding-bottom: 10px;
    }
  }
  &__td,
  &__th {
    &:not(:last-child) {
      padding-right: 4px;
    }
    border-radius: 0px;
  }

  &--bidder-ranking {
    width: 100%;
    /* Three items */
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ & {
      &:nth-child(1) {
        width: auto;
      }
    }
    @media #{$large-and-up} {
      width: 90%;
    }
    @media #{$extra-large-and-up} {
      width: 80%;
    }
    @media #{$ultra-large-and-up} {
      width: 60%;
    }
  }
}
