.actions {
  // @include flexbox();
  // @include align-items(center);
  white-space: nowrap;

  &__action {
    text-decoration: none;
    color: $base-button-background-color;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }
  }

  &__icon {
    vertical-align: middle;

    &:hover {
      color: $primary-color;
    }
  }
}

.hoverable-action {
  cursor: pointer;

  &--text {
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: 600;
  }

  &--light {
    color: lighten($primary-color, 4%);
  }

  &:hover {
    color: $primary-color;
  }
}
