.collection {
  .collection-item--fluid {
    @include flexbox();
    @include align-items(center);
  }
}

.collection-mui {
  &__header {
    text-transform: uppercase;
    font-family: $font-family-roboto;
    color: $primary-color;
    border-bottom: solid 2.5px $table-primary-border-color;
    padding: $collection-mui-padding;
  }
  &__item {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    line-height: 25px;
    font-size: 18px;
    font-weight: $font-weight-medium;
    padding: $collection-mui-padding;
    border-bottom: solid 1px $table-primary-border-color;

    &__content {
      padding: 10px 16px 16px 16px;
      line-height: 27px;
    }

    &__icon {
      width: 2rem;
      font-size: 1.6rem;
      display: inline-block;
      text-align: right;
      color: $collection-mui-icon-color;
    }
  }
}
