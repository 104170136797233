.bidding {
  &__col {
    font-weight: $font-weight-bold;

    & input {
      &:not([disabled]) {
        color: $primary-color !important;
      }

      &:not(:placeholder-shown) {
        width: 95% !important;

        @media #{$medium-and-down} {
          width: 85% !important;
        }
      }

      &.totalPrice {
        color: rgba(0, 0, 0, 0.4) !important;
      }
    }

    &.unavailable input.totalPrice {
      color: lightgray !important;
    }

    &--seller {
      padding-right: 1.26rem;
    }

    &--animated {
      @include animation(breathing, 4s, ease, infinite);
    }
    & .confirm-bid-btn {
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    & .icon--confirm-bid {
      font-size: 26px;

      @media #{$medium-and-down} {
        right: 0;
      }
    }
  }
}

@keyframes breathing {
  0% {
    @include box-shadow(0px, 1px, 1px, #8069de, true);
  }

  50% {
    @include box-shadow(0px, 1px, 45px, #8069de, true);
  }
}

$duration: 550ms;

:root {
  --bid-changed-duration: #{strip-unit($duration)};
}

.bid-changed {
  animation: blink $duration ease 1;
}

@keyframes blink {
  60% {
    background-color: lighten($primary-color, 5%);
  }
  100% {
    background-color: lighten($primary-color, 7%);
  }
}
