@import '_styles/partials/_vars.scss';

.flexWrapper {
  height: 100%;
  padding: 4px;

  .container {
    padding-bottom: 150px;

    .image {
      width: 400px;

      @media #{$medium-and-down} {
        width: 325.5px;
      }
    }

    h3 {
      margin-bottom: 0rem;
    }

    h6 {
      color: #3f3f3f;
    }
  }
}
