.auction-room-notification-panel {
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 20px;
  height: $auction-room-notification-height;
  width: 100%;
  color: $auction-room-notification-color;
  font-size: 14px;
  letter-spacing: 0.6px;
  background-color: $auction-room-notification-bg-color;
  overflow: hidden;
  padding: 0px 30px;

  @media #{$small-and-down} {
    height: 64px;
  }
}

.auction-room-notification {
  animation: rollDown 1.2s ease;

  @keyframes rollDown {
    0% {
      transform: translateY(-200%);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

// scroll bar has 17px and needs to be hidden
.notifications-wrapper {
  position: fixed;
  top: 75px;
  bottom: 20px;
  left: auto;
  z-index: 4;
  right: -18px;
  overflow: hidden;
}

.notifications {
  height: 100%;
  padding-right: 160px;
  overflow: auto;

  @media only screen and(max-width: $medium-screen + $nav-offset) {
    padding-right: 56px;
  }

  @media #{$small-and-down} {
    padding-right: 40px;
    padding-left: 24px !important;
  }
}

.notification {
  width: 640px;
  height: 160px;
  padding: 24px 21px;
  background-color: #edf2fa;
  margin-bottom: 5px;
  position: relative;

  @media only screen and(max-width: $medium-screen + $nav-offset) {
    width: 480px;
  }

  @media #{$small-and-down} {
    width: 100%;
  }

  &__title {
    display: inline-block;
    color: $notification-title-color;
    font-family: $font-family-roboto;
    font-weight: $font-weight-medium;
    font-size: 20px;
    margin-right: 10px;
  }

  &__date {
    color: #9a9a9a;
    font-size: 16px;
  }

  &__text {
    color: $notification-text-color;
    line-height: 24px;
    height: 75px;
    margin-bottom: 5px;
    text-align: justify;
  }

  &__new {
    float: right;
    color: #1eb9a2;

    @media #{$small-and-down} {
      position: absolute;
      top: 8px;
      right: 8px;
      float: none;
    }
  }

  &--read {
    background-color: #ffffff;
    box-shadow: none;
  }
}

.notifications-overlay {
  z-index: 3;
  top: -25%;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  opacity: 0.5;
  position: fixed;
  background: #000000;
  animation: fadeInFromNone 0.5s ease-out;
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
