.step {
  &__content {
    flex: 1;
    width: 100%;
  }
}

.stepper {
  &__footer {
    margin-top: 2.75rem;

    @media #{$medium-and-down} {
      margin-top: 2rem;
    }
  }
}
