@import '_styles/partials/_vars.scss';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 21px;
  margin-right: $card-margin-right;
  margin-bottom: 27px;
  margin-left: $card-margin-left;
  border-radius: 0 0 8px 8px;

  @media #{$medium-and-down} {
    margin: 1.2rem $card-side-margin-padding-medium;
  }
}

.label {
  vertical-align: middle;
  font-weight: $font-weight-bold;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: $base-font-color;
}

.dashboardBtnWrapper {
  margin-right: $card-margin-right;
  margin-bottom: 1.51875rem;
  text-align: right;
}
