.footer {
  background-color: $footer-bg-color;
  color: $footer-font-color;
  padding: 30px 30px 30px 30px;

  a:hover {
    filter: brightness(0.85);
  }

  @media #{$small-and-down} {
    padding: 15px;
    justify-content: center;
  }

  &__col {
    @media #{$small-and-down} {
      padding-bottom: 8px !important;
    }
  }

  &__help {
    text-align: left;

    @media #{$small-and-down} {
      text-align: center;
    }

    &__link {
      text-decoration: underline;
      color: #fff;
    }
  }

  &__copyright {
    text-align: center;

    @media #{$small-and-down} {
      text-align: center;
    }
  }

  &__legal-terms-wrapper {
    text-align: right;
    color: $footer-font-color;

    @media #{$small-and-down} {
      text-align: center;
    }
  }

  &__legal-terms {
    margin-right: 16px;
  }
}
