.status-label {
  width: 160px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  font-family: Roboto;
  color: #b3b3b3;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  padding: 8px 18px;
  text-transform: uppercase;

  &--primary {
    color: $primary-color;
    border-color: $primary-color;
  }

  &--secondary {
    color: #ba69de;
    border-color: #ba69de;
  }

  &--tertiary {
    color: #f02e6a;
    border-color: #f02e6a;
  }

  &--gold {
    color: #f5c113;
    border-color: #f5c113;
  }

  &--font {
    color: $base-font-color;
    border-color: $base-font-color;
  }

  &--grey {
    color: #cfcfcf;
    border-color: #cfcfcf;
  }

  &--tertiary-lighten {
    color: lighten(#f02e6a, 10%);
    border-color: lighten(#f02e6a, 10%);
  }
}
