.countdown {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;

  &__list {
    margin-bottom: 23px;

    &__item {
      display: inline-block;
      margin-right: 10px;

      &--last {
        margin-right: 0px;
      }
    }
  }

  &__label {
    font-weight: $font-weight-semi-bold;
  }

  &__circle {
    width: 50px;
    height: 50px;
    line-height: 45px;
    border: 1px solid $progressbar-color;
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto 10px auto;
    @include border-radius(50%);
    color: inherit;
    background-color: $background-color;

    @media #{$small-and-down} {
      width: 40px;
      height: 40px;
      line-height: 35px;
      font-size: 18px;
    }
  }

  &__information {
    display: flex;
    flex-wrap: nowrap;
    max-width: 600px;
    width: 100%;

    &__label {
      display: inline-block;
      text-align: right;
      flex: 1.1;
      line-height: 1rem;
    }

    &__date {
      display: inline-block;
      text-align: left;
      flex: 1;
      line-height: 1rem;
    }

    &--active {
      border-bottom: 1px solid $base-font-color;
    }
  }
}
