.nav--active {
  color: $navigation-button-active-color !important;
}

.sidenav__break {
  border: none;
  background-color: #3a3957;
  height: 1px;
  width: 232px;
}

nav {
  background-color: $base-button-background-color !important;
  font-family: $font-family-roboto;
  font-weight: $font-weight-medium;

  & .chip-wrapper {
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 16px;
  }

  & .chip {
    max-width: 230px;
  }

  i {
    &.left {
      margin-right: 10px;
    }
  }

  @media only screen and(max-width: $medium-screen + $nav-offset) {
    background-color: #ffffff !important;
  }

  ul a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
