.modal {
  outline: none;
  @extend .z-depth-5;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 24px;
  max-height: 80%;
  width: 40%;
  margin: auto;
  overflow-y: auto;
  // text-align: left;

  border-radius: 2px;
  will-change: top, opacity;

  &__close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &--small {
    width: 20%;

    @media #{$large-and-down} {
      width: 40%;
    }
  }

  &--medium {
    width: 60%;

    @media #{$large-and-down} {
      width: 70%;
    }
  }

  &--big {
    width: 80%;
    @media #{$large-and-down} {
      width: 90%;
    }
  }

  &__title {
    font-family: $font-family-roboto;
    font-weight: $font-weight-medium;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    display: inline-block;

    &--green {
      color: $primary-color;
    }
  }

  &__btn {
    &:hover {
      color: $primary-color;
    }
  }
  @media #{$medium-and-down} {
    width: 90%;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
  }
  .modal-header {
    text-align: left;
  }
  .modal-content {
  }
  .modal-close {
  }

  .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 56px;
    width: 100%;
    text-align: right;

    .btn,
    .btn-flat {
      margin: 6px 0;
    }
  }
}
.modal-overlay {
  position: fixed;
  z-index: 999;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;

  will-change: opacity;
}

// Modal with fixed action footer
.modal.modal-fixed-footer {
  padding: 0;
  height: 70%;

  .modal-content {
    position: absolute;
    height: calc(100% - 56px);
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
  }
}

// Modal Bottom Sheet Style
.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: 45%;
  border-radius: 0;
  will-change: bottom, opacity;
}
