/* Text Inputs + Textarea
   ========================================================================== */

/* Style Placeholders */

::placeholder {
  color: $input-label-placeholder-color;
}

.input-field label {
  font-size: 0.8rem;
  -webkit-transform: translateY(-140%);
  -moz-transform: translateY(-140%);
  -ms-transform: translateY(-140%);
  -o-transform: translateY(-140%);
  transform: translateY(-140%);
}

input[type='number'] {
  -moz-appearance: textfield;

  &:hover,
  &:focus {
    -moz-appearance: number-input;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    font-size: 32px;
    transform: scale(1.08);
  }
}

input[type='text'].invalid + label:after,
input[type='text']:focus.invalid + label:after,
input[type='password'].invalid + label:after,
input[type='password']:focus.invalid + label:after,
input[type='email'].invalid + label:after,
input[type='email']:focus.invalid + label:after,
input[type='url'].invalid + label:after,
input[type='url']:focus.invalid + label:after,
input[type='time'].invalid + label:after,
input[type='time']:focus.invalid + label:after,
input[type='date'].invalid + label:after,
input[type='date']:focus.invalid + label:after,
input[type='datetime-local'].invalid + label:after,
input[type='datetime-local']:focus.invalid + label:after,
input[type='tel'].invalid + label:after,
input[type='tel']:focus.invalid + label:after,
input[type='number'].invalid + label:after,
input[type='number']:focus.invalid + label:after,
input[type='search'].invalid + label:after,
input[type='search']:focus.invalid + label:after,
textarea.materialize-textarea.invalid + label:after,
textarea.materialize-textarea:focus.invalid + label:after {
  content: attr(data-error);
  color: #f44336;
  opacity: 1;
}

// Remove autofill yellow style on google chrome
@-webkit-keyframes autofill {
  to {
    color: $base-font-color;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

input:not([type]),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
textarea.materialize-textarea {
  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: $input-border;
  border-radius: 0;
  outline: none;
  height: $input-height;
  width: 100%;
  font-family: $font-family-roboto;
  font-size: $input-font-size;
  font-weight: $font-weight-normal;
  margin: $input-margin;
  padding: $input-padding;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;
  color: rgba(0, 0, 0, 0.87);

  // Disabled input style
  &:disabled,
  &[readonly='readonly'] {
    color: $input-disabled-color;
    border-bottom: $input-disabled-border;
  }

  // Disabled label style
  &:disabled + label,
  &[readonly='readonly'] + label {
    color: $input-disabled-color;
  }

  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
  }

  // Focused label style
  &:focus:not([readonly]) + label {
    color: $input-focus-color;
  }

  // Hide helper text on data message
  &.valid ~ .helper-text[data-success],
  &:focus.valid ~ .helper-text[data-success],
  &.invalid ~ .helper-text[data-error],
  &:focus.invalid ~ .helper-text[data-error] {
    @extend %hidden-text;
  }

  // Valid Input Style
  &.valid,
  &:focus.valid {
    @extend %valid-input-style;
  }

  // Custom Success Message
  &.valid ~ .helper-text:after,
  &:focus.valid ~ .helper-text:after {
    @extend %custom-success-message;
  }
  &:focus.valid ~ label {
    color: $input-success-color;
  }

  // Invalid Input Style
  &.invalid,
  &:focus.invalid {
    @extend %invalid-input-style;
  }

  // Custom Error message
  &.invalid ~ .helper-text:after,
  &:focus.invalid ~ .helper-text:after {
    @extend %custom-error-message;
  }
  &:focus.invalid ~ label {
    color: $input-error-color;
  }

  // Full width label when using validate for error messages
  &.validate + label {
    width: 100%;
  }

  // Form Message Shared Styles
  & + label:after {
    @extend %input-after-style;
  }
}

td .select-wrapper {
  & input[readonly='true'] {
    border-bottom: none !important;
    // color: inherit !important;
    cursor: default;
  }

  & svg {
    display: none;
  }
}

/* Validation Sass Placeholders */
%valid-input-style {
  border-bottom: 1px solid $input-success-color;
  box-shadow: 0 1px 0 0 $input-success-color;
}
%invalid-input-style {
  border-bottom: $input-invalid-border;
  box-shadow: 0 1px 0 0 $input-error-color;
}
%hidden-text {
  color: transparent;
  user-select: none;
  pointer-events: none;
}
%custom-success-message {
  content: attr(data-success);
  color: $input-success-color;
}
%custom-error-message {
  content: attr(data-error);
  color: $input-error-color;
}
%input-after-style {
  display: block;
  content: '';
  position: absolute;
  top: 65px;
  opacity: 0;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

ul.ui-autocomplete {
  color: red !important;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

// Styling for input field wrapper
.input-field {
  // Inline styles
  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    input,
    .select-dropdown {
      margin-bottom: 1rem;
    }
  }

  // Gutter spacing
  &.col {
    label {
      left: $gutter-width / 2;
    }

    .prefix ~ label,
    .prefix ~ .validate ~ label {
      width: calc(100% - 3rem - #{$gutter-width});
    }
  }

  & .material-icons {
    &:hover {
      color: $input-focus-color;
    }
  }

  @include modifier(search) {
    width: 100%;
    border: 1px solid black;
    @include border-radius(128px);
    border-color: #1e1d34;
    padding: 5px 8px 5px 8px;
    margin-right: 15px;
  }

  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > label {
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    color: $input-label-color;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);

    &:not(.label-icon).active {
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
  }

  & > label.active {
    color: $input-active-label;
  }

  // Autofill + date + time inputs
  & > input[type]:-webkit-autofill:not(.browser-default) + label,
  & > input[type='date']:not(.browser-default) + label,
  & > input[type='time']:not(.browser-default) + label {
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0;
  }

  .helper-text {
    &::after {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
    }

    position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }

  // Prefix Icons
  .prefix {
    position: absolute;
    width: $input-height;
    font-size: $input-icon-size;
    transition: color 0.2s;
    top: ($input-height - $input-icon-size) / 2;
    left: -10px;
    cursor: default;

    &.active {
      color: $input-focus-color;
    }

    &--search {
      left: inherit;
      color: $search-icon-placeholder-color;
      font-size: $input-search-icon-size;
      top: ($input-search-height - $input-search-icon-size) / 2;
    }
  }

  .prefix ~ input,
  .prefix ~ textarea,
  .prefix ~ label,
  .prefix ~ .validate ~ label,
  .prefix ~ .helper-text,
  .prefix ~ .autocomplete-content {
    margin-left: 2rem;
    width: 92%;
    width: calc(100% - 2rem);
  }

  .prefix ~ input[type='search']::placeholder {
    color: $search-icon-placeholder-color;
  }

  .prefix ~ label {
    margin-left: 2rem;
  }

  @media #{$medium-and-down} {
    .prefix ~ input {
      width: 86%;
      width: calc(100% - 2rem);
    }
  }

  @media #{$small-and-down} {
    .prefix ~ input {
      width: 80%;
      width: calc(100% - 2rem);
    }
  }
}

/* Search Field */

.input-field input[type='search'] {
  display: block;
  font-size: 16px;
  line-height: inherit;
  transition: 0.3s background-color;
  height: $input-search-height / 2 + 5px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  box-sizing: content-box;

  .nav-wrapper & {
    height: inherit;
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    box-shadow: none;
  }

  &:focus:not(.browser-default) {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: #444;

    & + label i,
    & ~ .mdi-navigation-close,
    & ~ .material-icons {
      color: #444;
    }
  }

  & + .label-icon {
    transform: none;
    left: 1rem;
  }

  & ~ .mdi-navigation-close,
  & ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: $input-icon-size;
    transition: 0.3s color;
  }
}

.input-field input[type='search']::placeholder {
  color: $input-label-placeholder-color !important;
  font-weight: 500;
}
/* Textarea */

// Default textarea
textarea {
  width: 100%;
  height: $input-height;
  background-color: transparent;

  &.materialize-textarea {
    line-height: normal;
    overflow-y: hidden; /* prevents scroll bar flash */
    padding: 0.8rem 0 0.8rem 0; /* prevents text jump on Enter keypress */
    resize: none;
    min-height: $input-height;
    box-sizing: border-box;
  }
}

// For textarea autoresize
.hiddendiv {
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem; /* prevents text jump on Enter keypress */

  // Reduces repaints
  position: absolute;
  top: 0;
  z-index: -1;
}

/* Autocomplete */
.autocomplete-content {
  li {
    .highlight {
      color: #444;
    }

    img {
      height: $dropdown-item-height - 10;
      width: $dropdown-item-height - 10;
      margin: 5px 15px;
    }
  }
}

/* Character Counter */
.character-counter {
  min-height: 18px;
}
