.collaborator-form {
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__field {
    flex: 1;
    margin-right: 24px;

    @media #{$large-and-down} {
      &--last {
        margin-right: 0;
      }
    }
  }
}

.filterDetails-form {
  &__search-in {
    margin-bottom: 40px !important;

    &__title {
      margin-bottom: 20px;
      height: auto;

      @media #{$ultra-large-and-up} {
        margin-right: 16px;
        height: 20px; // Hack to center
        margin-bottom: 0px;
      }
    }

    @media #{$ultra-large-and-up} {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__content {
        flex: 1;
      }
    }
  }
}
