@import 'reset';

html,
body {
  height: 100%;
}

body {
  background-color: $background-color;
  font-family: $font-family-mosk;
  font-weight: $font-weight-normal;
  overflow-y: scroll;
}

button,
a {
  border-radius: 28px;
}

#app {
  height: 100%;
}

%center-wrapper {
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

%roboto-text {
  font-family: $font-family-roboto;
  color: $base-font-color;
}

.loader {
  @extend %center-wrapper;

  &__text {
    @extend %roboto-text;
  }
}

.centered-flex-wrapper {
  @extend %center-wrapper;
}

%center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-wrapper {
  height: 100%;
  @extend %center-wrapper;
}

.dashboard-spinner-wrapper {
  @extend %center-wrapper;
  height: 299px;
  margin-bottom: 22.5px;
}

.no-more-data-available {
  font-size: 17px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  @media #{$medium-and-down} {
    font-size: 1.1rem;
    // min-height: 180px;
  }
}

//react-slick
.slick-slide div {
  text-align: center;
}

.slick-next,
slick-previous {
  &:before {
    opacity: 0.95 !important;
  }
}
