//Cross browser CSS3 mixins
@mixin box-shadow-w-spread($left, $top, $radius, $spread, $color) {
  box-shadow: $left $top $radius $spread $color;
  -webkit-box-shadow: $left $top $radius $spread $color;
  -moz-box-shadow: $left $top $radius $spread $color;
}

@mixin box-shadow($left, $top, $radius, $color) {
  box-shadow: $left $top $radius $color;
  -webkit-box-shadow: $left $top $radius $color;
  -moz-box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: linear) {
  transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin animation($name, $seconds, $style, $totaltime) {
  animation: $name $seconds $style $totaltime;
  -ms-animation: $name $seconds $style $totaltime;
  -webkit-animation: $name $seconds $style $totaltime;
  -moz-animation: $name $seconds $style $totaltime;
  -o-animation: $name $seconds $style $totaltime;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
  border-top-left-radius: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
}

@mixin gradient($color1, $color2) {
  background-color: $color1;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
  background-image: -moz-linear-gradient(center top, $color1, $color2);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}

@mixin linear-gradient-b($args...) {
  background-image: -o-linear-gradient($args);
  background-image: -moz-linear-gradient($args);
  background-image: -webkit-linear-gradient($args);
  background-image: linear-gradient($args);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin element($element) {
  &__#{$element} {
    @content;
  }
}

@mixin modifier($modifier) {
  &--#{$modifier} {
    @content;
  }
}

@mixin filter($filt) {
  -webkit-filter: $filt;
  -moz-filter: $filt;
  -o-filter: $filt;
  -ms-filter: $filt;
  filter: $filt;
}

// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}
