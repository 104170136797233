/*  Info window   */
.gm-style-iw {
  padding: 12px !important;
  // Close button
  & + button.gm-ui-hover-effect {
    width: 43px !important;
    & img {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.gm-style-iw-d {
  overflow: auto !important;
}

.map-control {
  &__label {
    padding: 4px 8px;
    border-radius: 3px;
    background-color: white;
    font-size: 20px;
    margin: 16px;

    &--top-c {
      @media #{$medium-and-down} {
        margin-top: 64px;
      }
    }

    &--btm-c {
      @media #{$small-and-down} {
        margin: 0 64px 16px 64px;
      }
    }
  }
}
