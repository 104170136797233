.fade-in {
  @include animation(fadein, 2s, ease, null);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shake {
  30% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  36% {
    transform: translate(1px, 1.5px) rotate(0deg);
  }
  42% {
    transform: translate(-1px, -2.5px) rotate(-10deg);
  }
  48% {
    transform: translate(-3px, 0px) rotate(10deg);
  }
  84% {
    transform: translate(3px, 1.5px) rotate(0deg);
  }
  60% {
    transform: translate(1px, -2.5px) rotate(10deg);
  }
  66% {
    transform: translate(-1px, 1.5px) rotate(-10deg);
  }
  72% {
    transform: translate(-3px, 1.5px) rotate(0deg);
  }
  78% {
    transform: translate(3px, 1.5px) rotate(-10deg);
  }
  84% {
    transform: translate(-1px, -2.5px) rotate(10deg);
  }
  90% {
    transform: translate(1px, 1.5px) rotate(0deg);
  }
  96% {
    transform: translate(1px, 1.5px) rotate(-10deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
