// Badges
span.badge {
  font-family: $font-family-roboto;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 14px;
  text-align: center;
  font-size: 10px;
  line-height: $badge-height;
  height: $badge-height;
  color: color('grey', 'darken-1');
  float: right;
  box-sizing: border-box;

  @include modifier(new){
    font-weight: $font-weight-ultra-bold;
    margin-left: 8px;
    color: #fff;
    background-color: $badge-bg-color;
    border-radius: 2px;
  }

  @include modifier(gold){
    background-color: $badge-gold-color;
  }
  @include modifier(silver){
    color: $badge-silver-color;
    background-color: #FFFFFF;
    border:1px solid $badge-silver-color;
    line-height: $badge-height - 2;
  }
  @include modifier(bronze){
    color: $badge-bronze-color;
    background-color: #FFFFFF;
    border: 1px solid $badge-bronze-color;
    line-height: $badge-height - 2;
  }

  &.new:after {
    content: " new";
  }

  &[data-badge-caption]::after {
    content: " " attr(data-badge-caption);
  }
}

// Special cases
nav ul a span.badge {
  display: inline-block;
  float: none;
  margin-left: 4px;
  line-height: $badge-height;
  height: $badge-height;
  -webkit-font-smoothing: auto;
}

// Line height centering
.collection-item span.badge {
  margin-top: calc(#{$collection-line-height / 2} - #{$badge-height / 2});
}
.collapsible span.badge {
  margin-left: auto;
}
.sidenav span.badge {
  margin-top: calc(#{$sidenav-line-height / 2} - #{$badge-height / 2});
}

table span.badge {
  display: inline-block;
  float: none;
}
