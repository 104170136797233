// GLOBAL
// ==========================================================================

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$extra-large-screen-up: 1401px !default;
$extra-large-screen: 1400px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

:root {
  --data-small-screen: #{strip-unit($small-screen)};
  --data-medium-screen: #{strip-unit($medium-screen)};
  --data-large-screen: #{strip-unit($large-screen)};
}

$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default;
$ultra-large-and-up: 'only screen and (min-width : #{$extra-large-screen-up})' !default;
$ultra-large-and-down: 'only screen and (max-width : #{$extra-large-screen})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
$large-and-down: 'only screen and (max-width : #{$large-screen})' !default;

//Offset from medium screen to change the navigation to side navigation
$nav-offset: 82px;

// f1e7c8

// FONT VARIABLES
// ==========================================================================

$font-family-mosk: 'Mosk', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-ultra-bold: 900;

// COLOR VARIABLES
// ==========================================================================

$primary-color: #1eb9a2;

$base-button-background-color: #1e1d34;
$base-button-color: #1e1d34;
$base-button-border-color: $base-button-background-color;
$background-color: #efefef;
$widget-background-color: #ffffff;

// Text
$text-color: #1e1d34;
$text-note-color: rgba(0, 0, 0, 0.54);

//header
$header-background-color: #ffffff;
$alert-color: #4d4d4d;
$login-button-border-color: #e3e3e3;
$login-button-font-color: #717171;

//navigation
$navigation-background-color: $base-button-background-color;
$navigation-font-color: #e0e0e0;
$navigation-button-icon-color: #c9c9cb;
$navigation-button-active-color: #30d7bf;

//dashboard
$dashboard-background-gradient-first-color: #6debce;
$dashboard-background-gradient-second-color: #34b5b5;
$dashboard-background-gradient-third-color: #81d7e6;
$dashboard-recently-bought-ltgreen-color: #66d6cc;
$dashboard-recently-bought-ltblue-color: #66c0d6;
$dashboard-recently-bought-violet-color: #b28ded;
$dashboard-recently-bought-pink-color: #d97bc0;
$dashboard-google-marker-background-color: #38c2a6;
$dashboard-google-marker-border-color: #1b9d83;

//progressbar (stepper)
$progressbar-color: $base-button-background-color;

//fonts
$base-font-color: $base-button-background-color;
$secondary-font-color: #343434;

//tables
$table-primary-border-color: #d0d0d0;
$table-secondary-border-color: #e2e2e2;
$table-first-cell-left-padding: 1.3rem;
$table-cell-top-bottom-padding: 0.6rem;
$table-cell-sides-padding: 1rem;

//Materialize overide
$input-focus-color: $primary-color !important;
$input-disabled-color: rgba(0, 0, 0, 0.42) !default;

//cards
$card-side-margin-padding: 1.8rem;
$card-side-margin-padding-medium: 1rem;
$card-suppliers-selection-background-color: #697bde;
$card-header-button-border-color: #e0e0e0;
$card-header-87p-white-color: rgba(255, 255, 255, 0.87);
$card-left-padding: $card-side-margin-padding;
$card-right-padding: $card-side-margin-padding;
$card-margin-left: $card-side-margin-padding;
$card-margin-right: $card-side-margin-padding;
$card-header-top-bottom-padding: 1.625rem;
$card-top-margin: 20px;

//buttons
$search-button-background-color: #1eb9a2;
$search-icon-placeholder-color: #8e8e8e;
$premium-users-button-color: #f5c113;

// Disabled styles
$button-disabled-background: #dfdfdf !default;
$button-disabled-color: #9f9f9f !default;

// Icons
$icon-gold-color: #f5c113;
$icon-silver-color: #9a9a9a;
$icon-bronze-color: #e2ad58;
$icon-none-color: #dbdbdb;
$icon-buyer-color: #697bde;
$icon-seller-color: #1eb9a2;
$icon-participant-color: #f02e6a;

// Badges
$badge-gold-color: $icon-gold-color;
$badge-silver-color: $icon-silver-color;
$badge-bronze-color: $icon-bronze-color;

// Footer
$footer-font-color: rgba(255, 255, 255, 0.95) !default;
$footer-bg-color: rgba(0, 0, 0, 0.6) !default;

// Inputs
$input-label-placeholder-color: rgba(0, 0, 0, 0.4) !default;
$input-active-label: rgba(0, 0, 0, 0.4);

// 7. Chips
// ==========================================================================
$chip-bg-color: #ffffff !default;
$chip-border-color: #e3e3e3 !default;
$chip-selected-color: #26a69a !default;
$chip-margin: 0px !default;

// 8. Clock
// ==========================================================================
$clock-wrapper-border-color: #e3e3e3;
$clock-label-color: rgba(0, 0, 0, 0.54);
$clock-minutes-color: rgba(0, 0, 0, 0.7);

// 9. Notifications
// ==========================================================================
$auction-room-notification-height: 50px;
$auction-room-notification-color: $base-font-color;
$auction-room-notification-bg-color: darken(#efefef, 10%);
$notification-title-color: #1e1d34;
$notification-text-color: rgba(0, 0, 0, 0.54);
$notification-button-color: #1eb9a2;

//10. Collections
$collection-mui-icon-color: #d1d1d1;
$collection-mui-padding: 10px 16px;
