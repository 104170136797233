.detail {
  height: 1.7rem;
  margin-bottom: 10px;

  &--last {
    margin-bottom: 5px;

    @media #{$medium-and-down} {
      margin-bottom: 20px;
    }
  }

  @media #{$medium-and-down} {
    height: 2.5rem;
    margin-bottom: 20px;
  }

  &__label {
    display: inline-block;
    width: 200px;

    @media #{$medium-and-down} {
      display: block;
    }
  }

  &__value {
    display: inline-block;

    @media #{$medium-and-down} {
      display: block;
    }
  }
}

.note {
  font-size: 11.5px;
  color: #3a3a3a;
  margin-top: 8px;
}
