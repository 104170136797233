@keyframes pop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.7);
  }
}

.counter {
  display: table;
  position: absolute;
  height: 17px;
  width: 17px;
  @include linear-gradient-b(180deg, #39c4a4, #31abc1);
  @include border-radius(50%);
  right: -8px;
  top: 0px;
  z-index: 3;
  animation: pop 0.3s linear 1;

  @media only screen and(max-width: $medium-screen + $nav-offset) {
    right: -3px;
    top: 23.5px;
  }

  @media #{$small-and-down} {
    top: 16px;
  }

  @include element(text) {
    display: table-cell;
    vertical-align: middle;
    font-family: $font-family-mosk;
    font-weight: $font-weight-bold;
    font-size: 10px;
    color: #ffffff;
    line-height: 11px;
    text-align: center;
  }
}
