.profile-map {
  &__header {
    @media #{$small-and-down} {
      justify-content: flex-end;
    }
  }
  &__search {
    width: auto;
    flex: 0.5 1 290px;

    @media #{$small-and-down} {
      margin-right: 0 !important;
      flex: 1 1 100%;
    }
  }
}
