.landing {
  padding: 25px 15px 15px;
  text-align: center;
  font-family: $font-family-mosk;
  width: 100%;
  margin: 0 auto;

  @media only screen and(min-width: 630px) {
    width: 600px;
  }

  h2 {
    font-size: 2.5rem;
  }

  &__heading {
    border-bottom: 1px solid $primary-color;
    box-shadow: 0 1px 0 0 $primary-color;
    display: inline-block;
  }

  &__logo-container {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 80%;
    margin: 0 auto;
    gap: 24px;

    a {
      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
    }

    &--single {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      img {
        width: 60%;
      }

      a {
        justify-content: center;
      }
    }
  }

  &__subheading {
    width: 100%;
    display: inline-block;
    -webkit-margin-before: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__security-container {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__security {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 10px;
      margin-right: 10px;
    }

    img {
      width: 100px;
    }
  }
}

.login {
  &__social {
    margin: 30px 0px 30px;

    &-icon {
      margin-right: 8px;
      cursor: pointer;
    }

    &__icons {
      margin-top: 15px;
    }
  }

  &__break {
    width: 250px;
    background-color: $primary-color;
    height: 2px;
    border: none;
  }

  &__form {
    margin-top: 10px;

    .password-visibility-icon {
      position: absolute;
      font-size: 30px;
      right: 0;
      cursor: pointer;
    }
  }

  &__forgot {
    display: inline-block;
    font-size: 14.5px;
    margin-top: 16px;

    &:hover {
      color: $primary-color;
    }
  }
}

#g-recaptcha {
  display: inline-block;
  text-align: center;
}

// Needs to be important because we're overriding materialize styles
.terms-of-service {
  color: $primary-color !important;
  font-weight: 500 !important;
}

.input-field label {
  font-size: 0.8rem;
  -webkit-transform: translateY(-140%);
  -moz-transform: translateY(-140%);
  -ms-transform: translateY(-140%);
  -o-transform: translateY(-140%);
  transform: translateY(-140%);
}

input[type='text'].invalid + label:after,
input[type='text']:focus.invalid + label:after,
input[type='password'].invalid + label:after,
input[type='password']:focus.invalid + label:after,
input[type='email'].invalid + label:after,
input[type='email']:focus.invalid + label:after,
input[type='url'].invalid + label:after,
input[type='url']:focus.invalid + label:after,
input[type='time'].invalid + label:after,
input[type='time']:focus.invalid + label:after,
input[type='date'].invalid + label:after,
input[type='date']:focus.invalid + label:after,
input[type='datetime-local'].invalid + label:after,
input[type='datetime-local']:focus.invalid + label:after,
input[type='tel'].invalid + label:after,
input[type='tel']:focus.invalid + label:after,
input[type='number'].invalid + label:after,
input[type='number']:focus.invalid + label:after,
input[type='search'].invalid + label:after,
input[type='search']:focus.invalid + label:after,
textarea.materialize-textarea.invalid + label:after,
textarea.materialize-textarea:focus.invalid + label:after {
  content: attr(data-error);
  color: #f44336;
  opacity: 1;
}
