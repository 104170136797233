a {
  text-decoration: none;
}

html {
  line-height: 1.5;

  @media only screen and (min-width: 0) {
    font-size: 13.5px;
  }

  @media only screen and (min-width: $small-screen-up) {
    font-size: 15px;
  }

  @media #{$large-and-up} {
    font-size: 16px;
  }

  font-family: $font-stack;
  font-weight: normal;
  color: $base-font-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.3;
  font-family: Roboto;
  color: $base-font-color;
}

// Header Styles
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}
h1 {
  font-size: $h1-fontsize;
  line-height: 110%;
  margin: ($h1-fontsize / 1.5) 0 ($h1-fontsize / 2.5) 0;
}
h2 {
  font-size: $h2-fontsize;
  line-height: 110%;
  margin: ($h2-fontsize / 1.5) 0 ($h2-fontsize / 2.5) 0;
}
h3 {
  font-size: $h3-fontsize;
  line-height: 110%;
  margin: ($h3-fontsize / 1.5) 0 ($h3-fontsize / 2.5) 0;
}
h4 {
  font-size: $h4-fontsize;
  line-height: 110%;
  margin: ($h4-fontsize / 1.5) 0 ($h4-fontsize / 2.5) 0;
}
h5 {
  font-size: $h5-fontsize;
  line-height: 110%;
  margin: ($h5-fontsize / 1.5) 0 ($h5-fontsize / 2.5) 0;
}
h6 {
  font-size: $h6-fontsize;
  line-height: 110%;
  margin: ($h6-fontsize / 1.5) 0 ($h6-fontsize / 2.5) 0;
}

// Text Styles
em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

.thin {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.flow-text {
  $i: 0;
  @while $i <= $intervals {
    @media only screen and (min-width: 360 + ($i * $interval-size)) {
      font-size: 1.2rem * (1 + (0.02 * $i));
    }
    $i: $i + 1;
  }

  // Handle below 360px screen
  @media only screen and (max-width: 360px) {
    font-size: 1.2rem;
  }
}

.label {
  font-family: $font-family-roboto;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);

  &--italic {
    font-style: italic;
  }
}

.fs-10 {
  font-size: 10px;
}
.fs-20 {
  font-size: 20px;
}
.fs-30 {
  font-size: 30px;
}
.fs-40 {
  font-size: 40px;
}

.error {
  font-family: $font-family-roboto;
  font-weight: 400;
  font-size: 10px;
  color: red;
  float: right;
  position: relative;
  top: 3px;
}

.support-text {
  color: #bdbdbd;
}
