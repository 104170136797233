.btn {
  width: auto;
  margin-right: 10px;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }

  &--transp-blue {
    background-color: transparent !important;
    color: $base-font-color !important;
    border: 1px solid $base-font-color !important;
  }

  &--white-blue {
    background-color: #ffffff !important;
    color: $card-suppliers-selection-background-color !important;
    &:hover {
      background-color: #ffffff;
    }
  }

  &--modal-danger {
    background-color: transparent !important;
    color: #cb2431 !important;

    &:hover {
      background-color: #cb2431 !important;
      color: #ffffff !important;
    }
  }

  &--danger {
    background-color: transparent !important;
    border: 1px solid #cb2431 !important;
    color: #cb2431 !important;

    &:hover {
      background-color: #cb2431 !important;
      color: #ffffff !important;
    }
  }

  &--blue-white {
    color: #ffffff !important;
    border: 1px solid $card-header-button-border-color !important;
  }

  &--ltgreen-white {
    color: #ffffff !important;
    background-color: $search-button-background-color !important;
  }

  &--show-o-premium {
    color: $base-font-color !important;
    background-color: #ffffff !important;
    border: 1px solid $premium-users-button-color !important;
    @include element(icon) {
      color: #f5c113;
      font-size: 15px !important;
    }
  }

  &--buyer {
    background: transparent !important;
    border: 1px solid $icon-buyer-color !important;
    color: $base-button-color !important;

    @include element(icon) {
      color: $icon-buyer-color;
      font-size: 15px !important;
    }
  }

  &--inv-suppliers {
    color: #ffffff !important;
    background-color: $search-button-background-color !important;
    box-shadow: 0px 0px 2px 2px !important;

    & i {
      animation: move-in 0.35s ease-in forwards, shake 4s 0.35s infinite;

      @keyframes move-in {
        0% {
          transform: translateX(-55px);
        }
        80% {
          transform: translateX(8px);
        }
        100% {
          transform: translateX(0px);
        }
      }
    }

    &:hover {
      & i {
        animation: move-away 0.35s ease-in forwards;

        @keyframes move-away {
          20% {
            transform: translateX(8px);
          }
          100% {
            transform: translateX(-55px);
          }
        }
      }
    }
  }

  &--supplier {
    background: transparent !important;
    border: 1px solid $icon-seller-color !important;
    color: $base-button-color !important;
    @include element(icon) {
      color: $icon-seller-color;
      font-size: 15px !important;
    }
  }

  &--participate {
    background: transparent !important;
    border: 1px solid $icon-participant-color !important;
    color: $base-button-color !important;

    @include element(icon) {
      color: $icon-participant-color;
      font-size: 15px !important;
    }
  }

  &--bidders-approval {
    height: 28px !important;
    padding: 0 20px !important;
    line-height: 28px !important;
    font-size: 11.5px !important;

    &-reject {
      &:hover {
        background-color: red !important;
      }
    }
  }

  &--fixed-w-small {
    width: 136px;
  }

  &--fixed-w-medium {
    width: 200px;
  }

  &--margin {
    margin-right: 15px;
    @media screen and (min-width: 600px) and (max-width: 992px) {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  &--last {
    margin-right: 0px;
  }

  &--search {
    padding: '1px 10px';
  }
}

.sideBar__btn {
  width: 244px;
  height: 40px;
  font-weight: $font-weight-semi-bold;
  font-size: 14px;
  line-height: 16px;
  background: transparent !important;
  color: $base-font-color !important;
  margin-bottom: 9px;
  text-transform: uppercase;
  border: 1px solid !important;
  border-color: $base-button-border-color !important;

  &:disabled {
    background-color: $button-disabled-background !important;
    color: $button-disabled-color !important;
    cursor: default;
  }

  @media #{$medium-and-down} {
    border: none !important;
    padding: 0 2px !important;
    margin-right: 0;
    width: auto;
    margin-bottom: 0;

    &:disabled {
      background-color: transparent !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }

  &--premium {
    color: #ffcc21 !important;
    background: $base-button-background-color !important;

    @media #{$medium-and-down} {
      color: $base-font-color !important;
      background: none !important;
    }
  }

  &--tender-visibility {
    padding: 0 !important;
  }

  &--active {
    background-color: #1e1d34 !important;
    color: #ffffff !important;

    @media #{$medium-and-down} {
      padding: 0 8px !important;
    }
  }
}

.btn-register {
  display: inline-block;
  margin-top: 20px;
  color: #000000;
  text-decoration: none;
  font-style: italic;
}

.btn-register:hover {
  color: $primary-color;
  cursor: pointer;
}

.btn--extra-large {
  margin: 19px;
  font-size: 18px !important;
  width: auto;
  height: 50px !important;
  padding: 0px 38px !important;
}

.btn--form {
  background-color: #ffffff !important;
  color: $base-button-color !important;
  font-size: 14px !important;
  line-height: 16px !important;
  width: auto;
  height: 40px;
  font-weight: $font-weight-semi-bold;
  border: 1px solid $base-button-border-color !important;

  &--has-comments {
    color: #8069de !important;
    border-color: #8069de !important;
    & i {
      color: #8069de !important;
    }
  }

  @media #{$small-and-down} {
    font-size: 12px !important;
    padding: 0 8px !important;
  }
}

.btn__step {
  padding: 0 64px !important;
  width: auto;
  height: 3.375rem !important;
  font-size: 1.125rem !important;
  font-weight: $font-weight-semi-bold;

  &:disabled {
    background-color: $button-disabled-background !important;
    color: $button-disabled-color !important;
  }

  @media #{$medium-and-down} {
    padding: 0 42px !important;
  }

  @media #{$small-and-down} {
    font-size: 0.91rem !important;
    padding: 0 10px !important;

    & i {
      margin-right: 0 !important;
      margin-left: 4px !important;
    }
  }

  @include modifier(previous) {
    margin-right: 20px;
    padding: 0px 42px !important;
    background-color: #ffffff !important;
    color: $base-button-color !important;
    border: 1px solid $base-button-border-color !important;

    @media #{$medium-and-down} {
      padding: 0 32px !important;
      margin-right: 12px;
    }

    @media #{$small-and-down} {
      // padding: 0 32px !important;
      margin-right: 6px;
      padding: 0 10px !important;

      & i {
        margin-left: 0 !important;
        margin-right: 4px !important;
      }
    }
  }
}

.btn--green:not([disabled]) {
  background-color: $primary-color !important;
  transition: box-shadow 0.1s !important;

  &:hover {
    background-color: lighten($primary-color, 2%) !important;
  }
}

.btn--icon {
  width: auto;
  height: auto;
  background: none;
  border: none;
  padding: 2px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    color: $input-focus-color;
    background: rgba(0, 0, 0, 0.1);
  }

  & i {
    vertical-align: middle;
  }
}

.btn--circle {
  width: 40px;
  height: 40px;
  font-family: $font-family-mosk;
  font-weight: $font-weight-semi-bold;
  font-size: 16px;
  background: none;
  vertical-align: middle;
  border: 1px solid $base-button-border-color;
  margin-right: 10px;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include modifier(active) {
    background-color: $base-button-background-color;
    color: #ffffff;
  }

  @include modifier(last) {
    margin-right: 0px;
  }

  @include element(icon) {
    vertical-align: middle;
  }
}

.btn--overview-close {
  width: 12.5rem;
  margin: 1.1875rem;
  font-size: 1.125rem !important;
  height: 3.125rem !important;
  padding: 0px 2.375rem !important;

  @media #{$small-and-down} {
    font-size: 1rem !important;
    width: 10rem !important;
  }
}
