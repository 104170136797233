.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
.col .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}

// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}
@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  &--no-margin-xs {
    @media #{$small-and-down} {
      margin-bottom: 0;
    }
  }

  &--zero {
    margin-bottom: 0px;
  }

  &--small {
    margin-bottom: 5px;
  }

  &--medium {
    margin-bottom: 10px;
  }

  // Clear floating children
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width 0 0;
    min-height: 1px;

    &[class*='push-'],
    &[class*='pull-'] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + '%');
      &.s#{$i} {
        width: $perc;

        @include reset-offset;

        @media #{$small-and-down} {
          @if $i == 12 {
            padding: 0;
          }
        }
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + '%');
      @include grid-classes('s', $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        &.m#{$i} {
          width: $perc;

          @include reset-offset;

          @if $i == 12 {
            padding: 0;
          }
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        @include grid-classes('m', $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        &.l#{$i} {
          width: $perc;
          @include reset-offset;
          padding: 0 $gutter-width 0 0;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        @include grid-classes('l', $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        &.xl#{$i} {
          width: $perc;
          padding: 0 $gutter-width 0 0;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        @include grid-classes('xl', $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$ultra-large-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        &.uxl#{$i} {
          padding: 0 $gutter-width 0 0;
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + '%');
        @include grid-classes('uxl', $i, $perc);
        $i: $i + 1;
      }
    }
  }
}
