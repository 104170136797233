.clickable-map {
  width: 82%;

  &-wrapper {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 25px;
    margin: 20px 0;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #66c0d6;
  stroke: #000000;
  stroke-linejoin: round;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #dfb26e;
  stroke: #000000;
  stroke-linejoin: round;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #d97bc0;
  stroke: #000000;
  stroke-linejoin: round;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00ac5f;
  stroke: #000000;
  stroke-linejoin: round;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #66d6cc;
  stroke: #000000;
  stroke-linejoin: round;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #99b9d1;
  stroke: #000000;
  stroke-linejoin: round;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #42c6f2;
  stroke: #000000;
  stroke-linejoin: round;
}
.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff0a2;
  stroke: #000000;
  stroke-linejoin: round;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #8b6b8c;
  stroke: #000000;
  stroke-linejoin: round;
}
.st9 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #b28ded;
  stroke: #000000;
  stroke-linejoin: round;
}
.st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffbec5;
  stroke: #000000;
  stroke-linejoin: round;
}
.st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #4c76e0;
  stroke: #000000;
  stroke-linejoin: round;
}
.st12 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #c2c2c2;
  stroke: #000000;
  stroke-linejoin: round;
}
.st13 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #5beb8f;
  stroke: #000000;
  stroke-linejoin: round;
}
.st14 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #d6a1e6;
  stroke: #000000;
  stroke-linejoin: round;
}
.st15 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #cdada4;
  stroke: #000000;
  stroke-linejoin: round;
}
.st16 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #2b6ed4;
  stroke: #000000;
  stroke-linejoin: round;
}
.st17 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff88c;
  stroke: #000000;
  stroke-linejoin: round;
}
.st18 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f2f2f2;
  stroke: #000000;
  stroke-linejoin: round;
}
.st19 {
  fill: none;
  stroke: #000000;
  stroke-linejoin: round;
}
.st20 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #66d6cc;
  stroke: #000000;
  stroke-linejoin: round;
}
.on {
  fill: #f06292;
  stroke: #b71c1c;
  stroke-width: 3;
}

.st0,
.st1,
.st2,
.st3,
.st4,
.st5,
.st6,
.st7,
.st8,
.st9,
.st10,
.st11,
.st12,
.st13,
.st14,
.st15,
.st16,
.st17,
.st18,
.st19,
.st20 {
  &:hover {
    fill: #f06292;
    stroke: #f06292;
  }
}
