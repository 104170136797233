//MAJOR SECTIONS - HEADER, MAIN CONTENT, FOOTER, SIDEBAR ... USE IDS FOR THOSE
//MODULE LAYOUT CLASSES

.app-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  // height: 100%;
}

.content-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1845px;
  margin: 34px auto;

  & .card {
    flex: 0.5 1 0%;
    min-width: 40%;

    @media #{$medium-and-down} {
      margin-top: 1rem;
      flex: 1 0 100%;
    }
  }

  @media only screen and(max-width: 1920px) {
    margin: 34px 24px;
  }

  @media #{$medium-and-down} {
    margin: 1.2rem 1rem;
  }
}

.wiki-collection-wrapper {
  padding-bottom: 40px;
}
