.alert {
  padding: 15px;
  margin-bottom: 20px;

  &--error {
    color: red;
  }

  &--success {
    color: $primary-color;
  }
}

// react-toastify alerts override
.alert-toast {
  border-radius: 5px !important;
  font-family: $font-family-roboto !important;
  font-size: 14px;
  font-weight: $font-weight-medium;
  @include box-shadow(1px, 0, 10px, rgba(0, 0, 0, 0.55) !important);
}
