.h-align-center {
  text-align: center;
}

.h-align-left {
  text-align: left;
}

.h-align-right {
  text-align: right;
}

.v-middle {
  vertical-align: middle;
}

.white-fg {
  color: white;
}

.unavailable {
  color: lightgray !important;
  pointer-events: none;
}

.disable-pointer-events {
  pointer-events: none;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.txt-transf-norm {
  text-transform: none !important;
}

.text-italic {
  font-style: italic;
}

.shrink {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt-clr {
  color: $text-color;
}

.d-hidden {
  display: none;
}

.display-block {
  display: block;
}

.display-inline-b {
  display: inline-block;
}

// Positioning
.valign-wrapper {
  display: flex;
  align-items: center;
}

.line-break {
  width: 100%;
}

.disabled {
}

// classic clearfix
.clearfix {
  clear: both;
}

.required {
  color: red;
}

// colors
.primary-clr {
  color: $primary-color !important;
}

.primary-bg-clr {
  background-color: $primary-color !important;
}

.blue-bg-clr {
  background-color: #697bde !important;
}

.red-clr {
  color: red;
}

.red-bg-clr {
  background-color: red;
}

//PADDINGS
.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

//MARGINS
.margin-top-0 {
  margin-top: 0px;
}

.margin-top-1 {
  margin-top: 1px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-45 {
  margin-top: 45px;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-right-1 {
  margin-right: 1px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-35 {
  margin-right: 35px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-45 {
  margin-right: 45px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-left-1 {
  margin-left: 1px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-left-45 {
  margin-left: 45px;
}

.last {
  margin-right: 0px !important;
}

.zero-margin-top-bottom {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.padding-left-12 {
  padding-left: 12px;
}

.hidden {
  visibility: hidden;
  display: none;
}

.margin-0 {
  margin: 0 !important;
}

.accordion {
  overflow: hidden;
  transition: all 0.8s ease;

  &--collapsed {
    height: 0;
  }
}

@media #{$medium-and-down} {
  .hide-on-medium-and-down {
    display: none !important;
  }
}

@media #{$large-and-up} {
  .hide-on-large-and-up {
    display: none !important;
  }
}

@media #{$small-and-down} {
  .hide-on-small {
    display: none !important;
  }
}

.hide-horizontall-scroll::-webkit-scrollbar {
  display: none;
}

.hide-horizontall-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.link {
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a.underline {
  text-decoration: underline;
}

.required-border {
  border-bottom: 2px solid #f5c113 !important;
}
