.overview {
  &__container {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @media #{$medium-and-down} {
      display: flex;
      flex-direction: column;
    }
  }

  &__card {
    margin-top: 0px;
  }

  &__image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;

    @media #{$small-and-down} {
      position: inherit;
      margin-bottom: 24px;
    }
  }

  &__image {
    border-radius: 8px;
    max-height: 300px;
    max-width: 350px;

    @media #{$large-and-down} {
      max-height: 200px;
      max-width: 250px;
    }

    @media #{$small-and-down} {
      max-width: 220px;
      max-height: 160px;
    }
  }

  &__content {
    width: 100%;
    padding: 24px;
    border-collapse: separate;

    @media #{$small-and-down} {
      padding: 0px;
    }

    &__section {
      position: relative;
      margin-bottom: 40px;
    }
  }

  &__row {
    width: 100%;
    padding: 0.8em;
    &:not(:last-child) {
      border-bottom: 1px solid $table-primary-border-color;
    }

    @media #{$medium-and-down} {
      margin-bottom: 1em;
    }

    &--info {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
    }
  }

  &__label {
    display: inline-block;
    width: 35%;
    text-align: left;
    padding-right: 10px;
    font-weight: $font-weight-bold;
    vertical-align: top;
    line-height: 24px;

    @media #{$medium-and-down} {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 0.5em;
      line-height: 16px;
    }

    &--share {
      line-height: 40px;
    }
  }

  &__information {
    display: inline-block;
    width: 65%;
    line-height: 24px;

    @media #{$medium-and-down} {
      display: block;
      width: 100%;
      line-height: 16px;
    }
    &__row {
      display: block;
      margin-bottom: 0.5em;
    }
  }

  &__share--btn {
    background: none !important;
    border: 1px solid !important;
    padding: 4px !important;
  }

  &__list {
    &__row {
      &:not(:first-child) {
        padding-top: 15px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $table-primary-border-color;
      }

      &--item-details {
        border-top: 1px solid $table-primary-border-color;
        border-bottom: 0px !important;
        padding: 20px 0;

        & > p {
          width: 35%;
        }
      }
    }

    &__list-item {
      padding: 0 0 10px;

      &--bidding {
        display: flex;
        justify-content: space-between;

        &-content {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 5px;
        }
      }

      &--summarize {
        & > span {
          width: 20%;
          display: inline-flex;
          align-items: center;
          flex-direction: row;

          i {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &__copy-btn {
    border: 1px solid black;
    padding: 6px;
    margin-left: 5px;

    &:hover {
      border-color: $primary-color;
      background: transparent;
    }
  }
}
