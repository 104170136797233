.card {
  display: inline-block;
  text-align: left;
  background-color: white;
  width: auto;
  height: auto;
  margin-top: $card-top-margin;
  @include border-radius(8px);

  &--dashboard {
    display: flex;
    flex-direction: column;

    & .card__body {
      flex: 1;
    }
  }

  &__header {
    height: auto;
    background-color: $base-button-background-color;
    padding-top: $card-header-top-bottom-padding;
    padding-bottom: $card-header-top-bottom-padding;
    padding-left: $card-left-padding;
    padding-right: $card-right-padding;
    color: $card-header-87p-white-color;
    font-weight: $font-weight-medium;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    @include border-radii(8px, 8px, 0, 0);

    &__action-wrapper {
      margin-top: -$card-header-top-bottom-padding;
      margin-bottom: -$card-header-top-bottom-padding;
    }

    &__label-wrapper {
      display: flex;
      align-items: center;

      @media #{$large-and-down} {
        width: 100%;
        padding-bottom: 5px;

        &:last-child {
          padding-bottom: 0px;
        }
      }
    }

    &--proportioned {
      justify-content: space-between;
    }

    &--centered {
      justify-content: center;
    }

    &--my-contacts {
      padding-left: $table-first-cell-left-padding;
      padding-right: $table-cell-sides-padding;
    }

    &--aligned-w-table {
      padding-left: $table-first-cell-left-padding;
      padding-right: $table-cell-sides-padding;
    }

    &--white {
      background-color: #ffffff;
      color: $base-font-color;
    }

    &--green {
      background-color: #1eb9a2;
    }

    &--supp-def {
      &__button {
        padding: 0 1.2rem !important;
      }

      @media only screen and (min-width: $medium-screen-up) and (max-width: 1100px),
        only screen and(max-width: 730px) {
        display: flex;
        flex-direction: column;

        &__button {
          margin-bottom: 5px;
          margin-right: 0;
          width: 280px;
          max-width: 100%;
        }
      }
    }
  }
  &__subheader {
    height: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: $card-left-padding;
    padding-right: $card-right-padding;

    @media #{$medium-and-down} {
      &--inv-by-email {
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
  &__content {
    &--scrollable {
      overflow: auto;
    }
    &--padding {
      padding: $card-side-margin-padding;
    }
    &--side-padding {
      padding: 0 $card-side-margin-padding;
    }
    &--big {
      width: 912px;
    }
  }
  &--fullWidth {
    width: 100%;
  }
  &--noRightBorderRadii {
    @include border-radii($topleft: 8px, $topright: 0px, $bottomleft: 8px, $bottomright: 0px);
  }
  &--noLeftBorderRadii {
    @include border-radii($topleft: 0px, $topright: 8px, $bottomleft: 0px, $bottomright: 8px);
  }

  &__title-wrapper {
    padding: 1.771875rem 0px 0.84375rem $card-margin-left;
  }

  &__title {
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    font-size: 14px;
    color: #1eb9a2;
    text-transform: uppercase;

    &--big {
      font-family: $font-family-mosk;
      font-weight: $font-weight-medium;
      text-transform: none;
      font-size: 20px;
    }
  }

  &__body {
    margin: 0 $card-margin-right 1.265625rem $card-margin-left;

    &--zero-margin {
      margin: 0px;
    }
  }

  &__table {
    text-align: left;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #000000;

    &--fullWidth {
      width: 100%;
      height: 100%;
    }

    &__thead {
      font-weight: $font-weight-bold;
    }

    &__td {
      padding: 1rem 0.5rem 1rem 0.5px;

      @media #{$medium-and-down} {
        padding: 0.6896551724137931rem 0.5rem 0.6896551724137931rem 0.5px;
      }

      &--actions {
        padding: inherit;
      }
    }
    &__tr {
      // line-height: 52px;
      border-top: 1px solid;
      border-bottom: none;
      border-color: $table-secondary-border-color;
    }
  }
  &__footer {
    margin-top: 21px;
    margin-right: $card-margin-right;
    margin-bottom: 27px;
    margin-left: $card-margin-left;
    @include border-radii(0, 0, 8px, 8px);

    &--fluid {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--paging {
      &__label {
        font-weight: $font-weight-normal;
        vertical-align: middle;
        line-height: 16px;
        font-size: 14px;
      }
    }
    &--align-right {
      text-align: right;
    }
    &--left {
      display: inline-block;
      width: 50%;
      text-align: left;
    }
    &--right {
      display: inline-block;
      width: 50%;
      text-align: right;
    }
    &__label {
      vertical-align: middle;
      font-weight: $font-weight-bold;
      font-size: 18px;
      line-height: 21px;
      color: $base-font-color;
    }
  }

  &__btn {
    margin-top: 22.5px;
  }

  $selection-container-height: 81px;
  &__selection-container {
    display: flex;
    align-items: center;
    height: $selection-container-height;
    background-color: $card-suppliers-selection-background-color;
    padding-left: $card-left-padding;
    padding-right: $card-right-padding;

    @media #{$small-and-down} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  &__selection {
    &__label {
      font-size: 1rem;
      font-weight: $font-weight-bold;
      line-height: $selection-container-height;
      color: #ffffff;
      margin-right: 18px;
    }
  }

  &__blur-wrapper {
    position: relative;
    overflow: hidden;
    @include border-radius(8px);
    margin-top: $card-top-margin;
  }

  &--blur {
    pointer-events: none;
    @include filter(blur(10px) opacity(0.5));
    margin-top: -$card-top-margin;
    overflow: hidden;
    z-index: 2;
  }

  &__close {
    width: 100%;
    height: 5px;
    position: relative;
    top: -15px;
    right: -15px;
    height: 5px;
  }
}

.search-container {
  @include flexbox();
  @include align-items(center);
  height: 100%;
  width: 65%;
  padding-left: $card-left-padding;
  padding-right: $card-right-padding;
  border-right: 1px solid #e0e0e0;
}

.show-container {
  @include flexbox();
  @include align-items(center);
  height: 100%;
  padding-left: $card-left-padding;
  padding-right: $card-right-padding;
}

.empty-header-card {
  &__header {
    @media #{$medium-and-down} {
      padding: 0.8rem 0;
    }
  }
}

@media #{$medium-and-down} {
  .card {
    &__header {
      &--proportioned {
        justify-content: center;
      }
      &__button {
        margin-bottom: 5px;
        margin-right: 0;
      }
      &__buttons {
        display: flex;
        flex-flow: column;
      }
    }
  }
}
