.dropdown {
  display: inline-block;
  margin-right: 10px;

  &__placeholder {
    color: $input-label-placeholder-color;
    font-weight: $font-weight-semi-bold;
  }

  &__button {
    $dropdown-background-color: #ffffff;

    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    color: $base-button-color;
    background-color: $dropdown-background-color;
    border: 1px solid $base-button-color;
    text-transform: none;

    padding-right: 14px;
    padding-left: 14px;

    &:focus {
      background-color: $dropdown-background-color;
    }

    &:hover {
      background-color: $dropdown-background-color;
    }

    &--small {
      width: 86px;
      height: 40px;
    }

    &--big-fs {
      font-size: 16px;
    }

    &__icon {
      height: 100%;
      vertical-align: middle;
      font-size: 22px !important;
      margin-left: 0px !important;
    }
  }
}

.dropdown-content {
  &:focus {
    outline: 0;
  }
  font-family: $font-family-roboto;
  font-weight: $font-weight-normal;

  @extend .z-depth-1;
  background-color: $dropdown-bg-color;
  margin: 0;
  display: none;
  min-width: 100px;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999; // TODO: Check if this doesn't break other things
  transform-origin: 0 0;

  li {
    &:hover,
    &.active {
      background-color: $dropdown-hover-bg-color;
    }

    &:focus {
      outline: none;
    }

    &.divider {
      min-height: 0;
      height: 1px;
    }

    & > a,
    & > span {
      font-size: 16px;
      color: $dropdown-color;
      display: block;
      line-height: 19px;
      padding: (($dropdown-item-height - 20) / 2) 16px;
    }

    & > span > label {
      top: 1px;
      left: 0;
      height: 18px;
    }

    // Icon alignment override
    & > a > i {
      height: inherit;
      line-height: inherit;
      float: left;
      margin: 0 24px 0 0;
      width: 24px;
    }

    clear: both;
    color: $off-black;
    cursor: pointer;
    min-height: $dropdown-item-height;
    line-height: 1.5rem;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 8px;
  }
}

body.keyboard-focused {
  .dropdown-content li:focus {
    background-color: darken($dropdown-hover-bg-color, 8%);
  }
}

// Input field specificity bugfix
.input-field.col .dropdown-content [type='checkbox'] + label {
  top: 1px;
  left: 0;
  height: 18px;
  transform: none;
}

.dropdown-trigger {
  cursor: pointer;
}
