.attachments {
  &__dropzone {
    display: inline-block;
  }

  &__uploading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__spinner {
    margin: 0 1rem;
  }

  &__accepted-files {
    font-size: 0.8125rem;
    margin-top: 8px;
  }
}
