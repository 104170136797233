.progressbar {
  display: inline-block;
  counter-reset: step;
  width: 60%;
  @media #{$large-and-down} {
    width: 90%;
  }
  @media #{$medium-and-down} {
    width: 100%;
  }
  @include element(step) {
    list-style-type: none;
    float: left;
    position: relative;
    text-align: center;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
    color: $progressbar-color;
    /* one item */
    &:first-child:nth-last-child(1) {
      width: 100%;
    }
    /* two items */
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ li {
      width: 50%;
    }
    /* three items */
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
    }
    /* four items */
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ li {
      width: 25%;
    }
    /* five items */
    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ li {
      width: 20%;
    }
    &:first-child:after {
      content: none;
    }
    &:before {
      content: counter(step) '.';
      counter-increment: step;
      width: 50px;
      height: 50px;
      line-height: 45px;
      border: 1px solid $progressbar-color;
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 0 auto 10px auto;
      @include border-radius(50%);
      color: inherit;
      background-color: $background-color;
    }
    &:after {
      content: '';
      position: absolute;
      width: 60%;
      height: 1px;
      background-color: #afafaf;
      top: 25px;
      left: -30%;
      z-index: -1;
    }
    @include modifier(active) {
      color: $primary-color;
      &:before {
        border-color: $primary-color;
      }
    }
    @include modifier(completed) {
      &:before {
        cursor: pointer;
        font-family: 'Material Icons';
        content: 'done';
      }
    }
  }
}
