// ICO MOON ICONS
@font-face {
  font-family: 'icomoon';
  src: url('../../_assets/fonts/Icomoon/icomoon.eot?vlzv3o');
  src: url('../../_assets/fonts/Icomoon/icomoon.eot?vlzv3o#iefix') format('embedded-opentype'),
    url('../../_assets/fonts/Icomoon/icomoon.ttf?vlzv3o') format('truetype'),
    url('../../_assets/fonts/Icomoon/icomoon.woff?vlzv3o') format('woff'),
    url('../../_assets/fonts/Icomoon/icomoon.svg?vlzv3o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-moon'],
[class*=' icon-moon'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    color: $base-button-background-color;
    font-size: 28px;
  }

  &:hover:before {
    color: $primary-color;
  }
}

.icon-moon-google:before {
  content: '\ea88';
}

.icon-moon-facebook:before {
  content: '\ea90';
}

.icon-moon-twitter:before {
  content: '\1f37b';
}

/* This is needed for some mobile phones to display the Google Icon font properly */
.material-icons {
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-size: 1.5rem;
}

i {
  &.disabled {
    pointer-events: none;
    color: #9f9f9f !important;
  }
}

.icon {
  &--dot {
    font-size: 12px !important;
    margin-right: 12px;
  }

  @include modifier(name) {
    font-size: 12px !important;
    margin-right: 8px;
    vertical-align: middle;
  }

  @include modifier(buyer) {
    color: $icon-buyer-color;
  }
  @include modifier(seller) {
    color: $icon-seller-color;
  }
  @include modifier(participant) {
    color: $icon-participant-color;
  }
  @include modifier(gold) {
    color: $icon-gold-color;
  }
  @include modifier(silver) {
    color: $icon-silver-color;
  }
  @include modifier(bronze) {
    color: $icon-bronze-color;
  }
  @include modifier(none) {
    color: $icon-none-color;
  }

  &--ba-user {
    color: $primary-color;
  }

  &--potential-user {
    color: $icon-none-color;
  }
}

//Utilites
.material-icons.md-14 {
  font-size: 14px;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-20 {
  font-size: 20px;
}

.material-icons.md-225 {
  font-size: 22.5px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-25 {
  font-size: 25px;
}

.material-icons.md-26 {
  font-size: 26px;
}

.material-icons.md-28 {
  font-size: 28px;
}

.material-icons.md-30 {
  font-size: 30px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons.md-128 {
  font-size: 128px;
}
.material-icons.md-150 {
  font-size: 150px;
}
.material-icons.md-200 {
  font-size: 200px;
}

.material-icons.success {
  @extend .green-text, .text-darken-4;
}

.material-icons.invalid {
  @extend .red-text, .text-darken-4;
}
