@font-face {
  font-family: "Roboto";
  src: url(../../_assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype"),
    url(../../_assets/fonts/Roboto/roboto-regular-webfont.woff) format("woff"),
    url(../../_assets/fonts/Roboto/roboto-regular-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(../../_assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype"),
    url(../../_assets/fonts/Roboto/roboto-medium-webfont.woff) format("woff"),
    url(../../_assets/fonts/Roboto/roboto-medium-webfont.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(../../_assets/fonts/Roboto/roboto-black-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Roboto/roboto-black-webfont.woff) format("woff"),
    url(../../_assets/fonts/Roboto/roboto-black-webfont.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Mosk";
  src: url(../../_assets/fonts/Mosk_Typeface/mosk_normal_400-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_normal_400-webfont.woff)
      format("woff"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_normal_400-webfont.woff2)
      format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mosk";
  src: url(../../_assets/fonts/Mosk_Typeface/mosk_medium_500-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_medium_500-webfont.woff)
      format("woff"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_medium_500-webfont.woff2)
      format("woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Mosk";
  src: url(../../_assets/fonts/Mosk_Typeface/mosk_semi-bold_600-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_semi-bold_600-webfont.woff)
      format("woff"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_semi-bold_600-webfont.woff2)
      format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mosk";
  src: url(../../_assets/fonts/Mosk_Typeface/mosk_bold_700-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_bold_700-webfont.woff)
      format("woff"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_bold_700-webfont.woff2)
      format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mosk";
  src: url(../../_assets/fonts/Mosk_Typeface/mosk_extra-bold_800-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_extra-bold_800-webfont.woff)
      format("woff"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_extra-bold_800-webfont.woff2)
      format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Mosk";
  src: url(../../_assets/fonts/Mosk_Typeface/mosk_ultra-bold_900-webfont.ttf)
      format("truetype"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_ultra-bold_900-webfont.woff)
      format("woff"),
    url(../../_assets/fonts/Mosk_Typeface/mosk_ultra-bold_900-webfont.woff2)
      format("woff2");
  font-weight: 900;
  font-style: normal;
}
