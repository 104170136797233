// ==========================================================================
// Materialize variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Badges
//  3. Buttons
//  4. Cards
//  5. Carousel
//  6. Collapsible
//  7. Chips
//  8. Date + Time Picker
//  9. Dropdown
//  10. Forms
//  11. Global
//  12. Grid
//  13. Navigation Bar
//  14. Side Navigation
//  15. Photo Slider
//  16. Spinners | Loaders
//  17. Tabs
//  18. Tables
//  19. Toasts
//  20. Typography
//  21. Footer
//  22. Flow Text
//  23. Collections
//  24. Progress Bar

// 1. Colors
// ==========================================================================

$primary-color: color('materialize-red', 'lighten-2') !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;

$secondary-color: $primary-color !default;
$success-color: color('green', 'base') !default;
$error-color: color('red', 'base') !default;
$link-color: $base-font-color !default;

// 2. Badges
// ==========================================================================

$badge-bg-color: $secondary-color !default;
$badge-height: 22px !default;

// 3. Buttons
// ==========================================================================

// Shared styles
$button-border: none !default;
$button-background-focus: lighten($secondary-color, 4%) !default;
$button-font-size: 0.875rem !default;
$button-icon-font-size: 1.4rem !default;
$button-height: 2.5rem !default;
$button-padding: 0 1.625rem !default;
$button-radius: 28px !default;

// Raised buttons
$button-raised-background: $base-button-background-color !default;
$button-raised-background-hover: darken($primary-color, 5%) !default;
$button-raised-color: #fff !default;

// Large buttons
$button-large-font-size: 15px !default;
$button-large-icon-font-size: 1.6rem !default;
$button-large-height: $button-height * 1.5 !default;
$button-floating-large-size: 56px !default;

// Small buttons
$button-small-font-size: 13px !default;
$button-small-icon-font-size: 1.2rem !default;
$button-small-height: $button-height * 0.9 !default;
$button-floating-small-size: $button-height * 0.9 !default;

// Flat buttons
$button-flat-color: #343434 !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Floating buttons
$button-floating-background: $secondary-color !default;
$button-floating-background-hover: $button-floating-background !default;
$button-floating-color: #fff !default;
$button-floating-size: 40px !default;
$button-floating-radius: 50% !default;

// 4. Cards
// ==========================================================================

$card-padding: 24px !default;
$card-bg-color: #fff !default;
$card-link-color: color('orange', 'accent-2') !default;
$card-link-color-light: lighten($card-link-color, 20%) !default;

// 5. Carousel
// ==========================================================================

$carousel-height: 400px !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;

// 6. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

// 8. Date + Time Picker
// ==========================================================================

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: #999;
$datepicker-weekday-color: rgba(0, 0, 0, 0.87) !default;
$datepicker-weekday-bg: darken($secondary-color, 7%) !default;
$datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, 0.7) !default;
$datepicker-focus: rgba(0, 0, 0, 0.05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;
$datepicker-day-focus: transparentize(desaturate($secondary-color, 5%), 0.75) !default;
$datepicker-disabled-day-color: rgba(0, 0, 0, 0.3) !default;

$timepicker-clock-color: rgba(0, 0, 0, 0.87) !default;
$timepicker-clock-plate-bg: #eee !default;

// 9. Dropdown
// ==========================================================================

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: rgba(0, 0, 0, 0.87) !default;
$dropdown-item-height: 32px !default;

// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 2.2rem !default;
$input-search-height: 40px !default;
$input-border-color-override: rgba(0, 0, 0, 0.18);
$input-label-color: $input-label-placeholder-color;
$input-border-color: color('grey', 'base') !default;
$input-border: 1px solid $input-border-color-override !default;
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $secondary-color !default;
$input-font-size: 1rem !default;
$input-margin-bottom: 0px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$label-font-size: 12px !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$input-icon-size: 2rem;
$input-search-icon-size: 30px;
$placeholder-text-color: $input-label-placeholder-color !default;

// Radio Buttons
$radio-fill-color: $secondary-color !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.9) !default;
$select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-option-hover: rgba(0, 0, 0, 0.08) !default;
$select-option-focus: rgba(0, 0, 0, 0.08) !default;
$select-option-selected: rgba(0, 0, 0, 0.03) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0, 0, 0, 0.3) !default;

// Switches
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(lighten($switch-bg-color, 25%), 25%) !default;
$switch-unchecked-bg: #f1f1f1 !default;
$switch-unchecked-lever-bg: rgba(0, 0, 0, 0.38) !default;
$switch-radius: 15px !default;

// 12. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.375rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width * 2)/3 !default;

// 13. Navigation Bar
// ==========================================================================

$navbar-height: 73px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 14px !default;
$navbar-font-color: $navigation-font-color !default;
$navbar-brand-font-size: 3.5rem !default;

// 14. Side Navigation
// ==========================================================================

$sidenav-width: 266px !default;
$sidenav-font-size: 17px !default;
$sidenav-font-color: #ffffff !default;
$sidenav-bg-color: $navigation-background-color !default;
$sidenav-padding: 8px !default;
$sidenav-item-height: 48px !default;
$sidenav-line-height: $sidenav-item-height !default;
$collapsible-padding: 0.75rem 0.6875rem 0.6875rem 1.625rem !default;

// 15. Photo Slider
// ==========================================================================

$slider-bg-color: color('grey', 'base') !default;
$slider-bg-color-light: color('grey', 'lighten-2') !default;
$slider-indicator-color: color('green', 'base') !default;

// 16. Spinners | Loaders
// ==========================================================================

$spinner-default-color: $secondary-color !default;

// 17. Tabs
// ==========================================================================

$tabs-underline-color: #18e6c8 !default;
$tabs-text-color: #ffffff !default;
$tabs-bg-color: $base-button-background-color !default;

// 18. Tables
// ==========================================================================

$table-border-color: $table-primary-border-color !default;
$table-striped-color: rgba(242, 242, 242, 0.5) !default;

// 19. Toasts
// ==========================================================================

$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;
$toast-action-color: #eeff41;

// 20. Typography
// ==========================================================================

$font-stack: 'Mosk', 'Roboto', sans-serif !default;
$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1.15rem !default;

// 21. Footer
// ==========================================================================
$footer-copyright-font-color: rgba(255, 255, 255, 0.8) !default;
$footer-copyright-bg-color: rgba(51, 51, 51, 0.08) !default;

// 22. Flow Text
// ==========================================================================

$range: $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

// 23. Collections
// ==========================================================================

$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
$collection-active-bg-color: $secondary-color !default;
$collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;
$collection-link-color: $secondary-color !default;
$collection-line-height: 1.5rem !default;

// 24. Progress Bar
// ==========================================================================

$progress-bar-color: $secondary-color !default;
