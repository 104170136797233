.account {
  display: inline-block;
  height: 100%;

  &__body {
    height: 100%;
    padding-right: 35px;
    @include flexbox();
    @include align-items(center);
  }

  &__chip {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include border-radius(25px !important);
    border: 1px solid $chip-border-color;
  }

  &__notifications {
    margin-right: 20px;
    position: relative;
    color: $text-color;

    @media only screen and (max-width: $medium-screen + $nav-offset) {
      display: inline-block;
      float: right;
      margin-right: 0;
    }
  }
}
