.must-conditions-form-wrapper {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$medium-and-down} {
    padding: 1rem;
  }
}

.must-conditions-form {
  font-size: 16px;
  text-align: left;

  &__heading {
    font-weight: $font-weight-semi-bold;
    font-size: 22px;
  }

  &__list {
    margin: 30px 0;
  }

  &__item {
    margin-bottom: 1.2rem;

    @media #{$small-and-down} {
      margin-bottom: 0.8rem;
    }
  }

  &__input {
    margin: 0px !important;
    width: 500px;

    @media #{$large-and-down} {
      width: 100%;
    }
  }

  &__rply-heading {
    display: inline-block;
    font-weight: $font-weight-bold;
    margin-bottom: 15px;
  }
}
