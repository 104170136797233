.tooltip {
  position: relative; // @include element(text){

  &:hover:after {
    position: absolute;
    white-space: pre;
    background: $primary-color;
    @include border-radius(2px);
    font-family: $font-family-roboto;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    bottom: 26px;
    color: #fff;
    content: attr(data-title);
    text-decoration: none;
    padding: 5px 10px 16px 10px;
    top: 150%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    letter-spacing: 0.8px;
    z-index: 1;
  }

  @include modifier(margin) {
    &:hover:after {
      -webkit-transform: translateX(-57%);
      -moz-transform: translateX(-57%);
      -ms-transform: translateX(-57%);
      -o-transform: translateX(-57%);
      transform: translateX(-57%);
    }
  }
}

.validation-tooltip {
  position: relative;

  &:after {
    white-space: pre;
    background: $primary-color;
    @include border-radius(2px);
    font-family: $font-family-roboto;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    bottom: 26px;
    color: #fff;
    content: attr(data-title);
    text-decoration: none;
    padding: 5px 10px 16px 10px;
    top: 125%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    letter-spacing: 0.8px;
    z-index: 1;
  }

  &--error:after {
    background: red;
  }

  @include modifier(margin) {
    &:hover:after {
      -webkit-transform: translateX(-57%);
      -moz-transform: translateX(-57%);
      -ms-transform: translateX(-57%);
      -o-transform: translateX(-57%);
      transform: translateX(-57%);
    }
  }
}

.custom-tooltip {
  color: #fff !important;
  background-color: $primary-color !important;
  opacity: 1 !important;
  padding: 6px 14px !important;
  z-index: 9999;

  &.place-top {
    &:after {
      border-top-color: $primary-color !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
      opacity: 1 !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: $primary-color !important;
      border-bottom-style: solid !important;
      border-bottom-width: 6px !important;
      opacity: 1 !important;
    }
  }

  &.err-tooltip {
    background-color: red !important;

    &.place-top {
      &:after {
        border-top-color: red !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
        opacity: 1 !important;
      }
    }

    &.place-bottom {
      &:after {
        border-bottom-color: red !important;
        border-bottom-style: solid !important;
        border-bottom-width: 6px !important;
        opacity: 1 !important;
      }
    }
  }
}

.image-tooltip {
  opacity: 1 !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  padding: 0px !important;
  font-size: 0 !important;

  & img {
    max-height: 200px;
    max-width: 250px;
    border-radius: 8px;
    padding: 0;
    margin: 0;
  }

  &.place-top {
    &:after {
      opacity: 0.2 !important;
    }
  }

  &.place-bottom {
    &:after {
      opacity: 0.2 !important;
    }
  }
}
